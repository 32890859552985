<style scoped>
.prev {
  position: absolute;
  top: 50%;
  left: 10px;
  width: 40px;
  transform: translate(10px, -50%);
  opacity: 0.3;
  cursor: pointer;
}
.prev:hover {
  opacity: 1;
}
.next {
  width: 40px;
  transform: translate(10px, -50%);
  position: absolute;
  top: 50%;
  right: 40px;
  opacity: 0.3;
  cursor: pointer;
}
.next:hover {
  opacity: 1;
}
.img_list {
  height: 110px;
  width: 725px;
  float: left;
  margin: 5px 0px;
}
.img_list li {
  margin: 0 3px;
  cursor: pointer;
  float: left;
  height: 100px;
  list-style-type: none;
}
.img_list li:hover {
  opacity: 1;
}
.img {
  opacity: 0.3;
}
.bigImg {
  width: auto;
  max-height: 500px;
}
.img_active {
  opacity: 1;
}
.thumb_box {
  width: 768px;
  height: 110px;
  margin: 0 auto;
  border: 1px solid #ccc;
}
.thumb_prev,
.thumb_next {
  width: 20px;
  line-height: 110px;
  float: left;
  text-align: center;
  cursor: pointer;
}
.thumb_prev:hover,
.thumb_next:hover {
  background-color: #eee;
}
</style>
<template>
  <div v-if="imgList.length">
    <div>
      <div class="prev" @click.stop="prev" v-if="list.length > 1">
        <Icon type="ios-arrow-back" size="80" color="#ccc" />
      </div>
      <div>
        <div class="text-center">
          <img
            class="bigImg"
            :src="bigImg + '?x-oss-process=image/resize,w_768'"
          />
        </div>
      </div>
      <div class="next" @click.stop="next" v-if="list.length > 1">
        <Icon type="ios-arrow-forward" size="80" color="#ccc" />
      </div>
    </div>
    <div class="thumb_box">
      <div class="thumb_prev" @click="prev">
        <Icon type="ios-arrow-back" size="20" color="#C4C4C4"></Icon>
      </div>
      <ul class="img_list">
        <li
          v-for="(itme, index) in imgList"
          :key="index"
          :class="index === num ? 'img_active' : 'img'"
          @click="checkImg(index)"
        >
          <img
            :src="itme + '?x-oss-process=image/resize,h_100'"
            width="174"
            height="100"
          />
        </li>
      </ul>
      <div class="thumb_next" @click="next">
        <Icon type="ios-arrow-forward" size="20" color="#C4C4C4"></Icon>
      </div>
    </div>
  </div>
  <div v-else class="default-content" style="min-height:180px;">
    <p style="font-size: xx-large">抱歉，暂无图片</p>
  </div>
</template>

<script>
export default {
  name: 'ImgLightBoxComponent',
  props: {
    imgIndex: {
      type: Number,
      required: false
    },
    sortIndex: {
      // 实际排序
      type: Number,
      required: false
    }
  },
  data () {
    return {
      num: 0,
      length: 4,
      startIndex: 0,
      pageNumber: 1,
      list: [],
      imgList: [],
      bigImg: '',
      sortNumber: 0
    }
  },
  methods: {
    init (data) {
      // 初始化时清空数据
      this.pageNumber = 1
      this.startIndex = 0
      // 赋值
      this.list = data.imgList
      this.num = data.startIndex
      if (data.imgList.length > this.length) {
        this.pageNumber = parseInt(this.num / this.length) + 1
        if (this.pageNumber > 1) {
          this.startIndex = (this.pageNumber - 1) * this.length
          this.num = data.startIndex - this.length * (this.pageNumber - 1)
          this.imgList = this.list.slice(
            this.startIndex,
            this.length * this.pageNumber
          )
        } else {
          this.imgList = data.imgList.slice(0, this.length)
        }
      } else {
        this.imgList = data.imgList
      }
      this.bigImg = this.imgList[this.num]
    },
    prev () {
      if (this.pageNumber > 1) {
        if (this.num === 0) {
          this.pageNumber--
          this.num = this.length
        }
        this.startIndex = this.pageNumber * this.length - this.length
        this.imgList = this.list.slice(
          this.startIndex,
          this.length * this.pageNumber
        )
        this.bigImg = this.imgList[this.num]
      }
      if (this.num > 0) {
        this.num--
      }
      this.bigImg = this.imgList[this.num]
      this.sortNumber =
        this.pageNumber > 1
          ? (this.pageNumber - 1) * this.length + this.num
          : this.num
      this.$emit('update:sortIndex', this.sortNumber)
    },
    next () {
      if (
        (this.num + 1) % this.length === 0 &&
        (this.num + 1) * this.pageNumber < this.list.length
      ) {
        this.pageNumber++
        this.num = 0
        this.startIndex = (this.pageNumber - 1) * this.length
        this.imgList = this.list.slice(
          this.startIndex,
          this.length * this.pageNumber
        )
      } else {
        if (this.num < this.imgList.length - 1) {
          this.num++
        }
      }
      if (this.imgList.length > 0) {
        this.bigImg = this.imgList[this.num]
      }
      this.sortNumber =
        this.pageNumber > 1
          ? (this.pageNumber - 1) * this.length + this.num
          : this.num
      this.$emit('update:sortIndex', this.sortNumber)
    },
    checkImg (index) {
      this.num = index
      this.bigImg = this.imgList[index]
      this.sortNumber =
        this.pageNumber > 1
          ? (this.pageNumber - 1) * this.length + this.num
          : this.num
      this.$emit('update:sortIndex', this.sortNumber)
    },
    viewBigImg () {
      window.open(this.bigImg)
    }
  },
  watch: {
    num (val) {
      this.$emit('update:imgIndex', val)
    }
  }
}
</script>
