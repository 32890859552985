<template>
    <div>
        <SvgLine ref="svgline" @map-finished="handleMapLoadFinished" @on-click-station="handleClickStation"/>

        <SvgStationDemand ref="svgStationDemand" :actionDate="actionDate"/>
    </div>
</template>

<script>
import SvgLine from '@/components/svg/SvgLine'
import SvgStationDemand from '@/components/svg/SvgStationDemand'

export default {
  components: {
    SvgLine, SvgStationDemand
  },
  data () {
    return {
      mapFinished: false,
      actionDate: '' // 选中的作业令日期
    }
  },
  methods: {
    handleMapLoadFinished (val) {
      this.mapFinished = val
    },
    LoadMapData (stations, isEdit, chooseDate) {
      if (this.mapFinished) { // 验证地图是否加载完毕， 否则延迟1秒再次请求
        this.actionDate = chooseDate
        if (isEdit) {
          this.$refs.svgline.loadFlickerStations(stations.map(x => x.id))
        } else {
          this.$refs.svgline.loadResourceStations(stations)
        }
      } else {
        setTimeout(() => {
          this.LoadMapData(stations, isEdit, chooseDate)
        }, 1000)
      }
    },
    hideOtherCompanyLine () {
      if (this.mapFinished) { // 验证地图是否加载完毕， 否则延迟1秒再次请求
        this.$refs.svgline.hideOtherAsset(this.assetArray.map(x => x.id))
      } else {
        setTimeout(() => {
          this.hideOtherCompanyLine()
        }, 1000)
      }
    },
    handleClickStation (stationId) { // 地图点击站点事件
      this.$refs.svgStationDemand.handleShowStationDemand(stationId)
    }
  },
  computed: {
    assetArray () {
      return this.$store.state.actionDate.assetArray
    }
  },
  watch: {
    assetArray (val) {
      if (val.length > 0) {
        this.hideOtherCompanyLine()
      }
    }
  }
}
</script>
