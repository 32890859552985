import request from '@/utils/requestV2'
const qs = require('qs')

// 删除作业令
export function deleteWorkday (data) {
  return request({
    url: '/ooh-msp/v2/workday/deleteworkday',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取作业令已设置站点集合
export function getStationList (data) {
  return request({
    url: '/ooh-msp/v2/workday/getstationlist',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取一段时间内的作业令时间集合
export function getWorkdayList (data) {
  return request({
    url: '/ooh-msp/v2/workday/getworkdaylist',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 设置作业令时间
export function setWorkday (data) {
  return request({
    url: '/ooh-msp/v2/workday/setworkday',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 获取一段时间内的作业令时间集合
export function getList (data) {
  return request({
    url: '/ooh-msp/v2/workday/getlist',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 增加作业令时间
export function add (data) {
  return request({
    url: '/ooh-msp/v2/workday/add',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 发起作业令申请
export function apply (data) {
  return request({
    url: '/ooh-msp/v2/workday/apply',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 确认作业令
export function confirm (data) {
  return request({
    url: '/ooh-msp/v2/workday/confirm',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 一键派单
export function assignTaskitem (data) {
  return request({
    url: '/ooh-msp/v2/workday/assigntaskitem',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 删除站点作业令(逻辑删除)
export function delWorkday (data) {
  return request({
    url: '/ooh-msp/v2/workday/delete',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 更新作业时段
export function updateWorkTime (data) {
  return request({
    url: '/ooh-msp/v2/workday/updateworktime',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 分页获取计划作业品牌信息
export function getBrandPage (data) {
  return request({
    url: '/ooh-msp/v2/workday/getbrandpage',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 动态作业看板推荐计划作业汇总列表
export function gatherPage (data) {
  return request({
    url: '/ooh-msp/v2/workday/gatherpage',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 计划作业 按任务类型统计
export function getTaskitemTypeStat (data) {
  return request({
    url: '/ooh-msp/v2/workday/gettaskitemtypestat',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 计划作业 按供应商统计
export function getSupplierStat (data) {
  return request({
    url: '/ooh-msp/v2/workday/getsupplierstat',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 分页获取指定计划作业令站点列表
export function getStationPage (data) {
  return request({
    url: '/ooh-msp/v2/workday/getstationpage',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 获取指定作业日期站点任务作业类型
export function getTaskitemType (data) {
  return request({
    url: '/ooh-msp/v2/workday/gettaskitemType',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 分页获取站点任务信息
export function getTaskitemPage (data) {
  return request({
    url: '/ooh-msp/v2/workday/gettaskitempage',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 获取作业计划包含的资产，站点数据
export function getAssetDomainList (data) {
  return request({
    url: '/ooh-msp/v2/workday/getassetdomainlist',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 延期任务作业日期
export function delay (data) {
  return request({
    url: '/ooh-msp/v2/workday/delay',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 手动录入作业令
export function addWorkday (data) {
  return request({
    url: '/ooh-msp/v2/workday/addworkday',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 作业令站点明细
export function getWorkdayDetailPage (data) {
  return request({
    url: '/ooh-msp/v2/workday/getworkdaydetailpage',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 分页获取作业令汇总
export function getWorkdayGatherPage (data) {
  return request({
    url: '/ooh-msp/v2/workday/getworkdaygatherpage',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 获取作业令模板列表
export function getWorkdayNamelist (data) {
  return request({
    url: '/ooh-msp/v2/workday/getworkdaynamelist',
    method: 'post',
    data: qs.stringify(data)
  })
}
