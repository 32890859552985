import { getWorkdayDetailPage, getWorkdayGatherPage } from '@/api/msp/workday'

const getDefaultState = () => {
  return {
    curWorkday: '',
    curDaytypes: [],
    pageQuery: {
      pageNumber: 1,
      pageSize: 15
    },
    tableData: {},
    assetArray: [],
    allStationArray: [],
    workerArray: [],
    workTaskInfo: {},
    operate: 1 // 1:新增,2:编辑
  }
}

const state = getDefaultState()

const actionDate = {
  state,
  mutations: {
    reset_state (state) {
      Object.assign(state, getDefaultState())
    },
    set_cur_workday (state, data) {
      state.curWorkday = data
    },
    set_cur_daytypes (state, data) {
      state.curDaytypes = data
    },
    set_page_query (state, data) {
      state.query = data
    },
    set_table_data (state, data) {
      state.tableData = data
    },
    set_asset_array (state, data) {
      state.assetArray = data
    },
    set_all_station_array (state, data) {
      state.allStationArray = data
    },
    set_worker_array (state, data) {
      state.workerArray = data
    },
    set_work_task_info (state, data) {
      state.workTaskInfo = data
    },
    set_operate (state, data) {
      state.operate = data
    }
  },
  actions: {
    /**
     * 分页获取作业令数据
     * @param {Object} query 查询条件
     * @returns
     */
    getWorkdayData ({ state }, query) {
      return new Promise((resolve) => {
        getWorkdayGatherPage(query).then(res => {
          resolve(res)
        })
      })
    },
    /**
     * 获取作业令详情列表数据
     * @param {Object} param0
     * @param {Object} query
     * @returns Object
     */
    getActionDateDetailData ({ state, commit }, query) {
      const newQuery = Object.assign(state.pageQuery, query)
      commit('set_page_query', newQuery)
      return new Promise((resolve) => {
        getWorkdayDetailPage(newQuery).then(res => {
          commit('set_table_data', res)
          resolve(res)
        })
      })
    }
  }
}

export default actionDate
