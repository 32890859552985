<template>
  <div>
    <container>
      <div
        slot="left_box_content"
        class="p-l-5 p-r-5"
      >
        <component
          :is="curLeftComponent"
          @on-show-mapStations="handleLoadMapData"
          @loadBottomData="handleLoadBottomData"
        />
      </div>
      <div slot="bottom_box_content">
        <component
          :is="curBottomComponent"
          ref="actionDate-bottm"
        ></component>
      </div>
      <div slot="bg_map">
        <center ref="actionDate-center" />
      </div>
    </container>
  </div>
</template>

<script>
// 引入store模块
import actionDateStoreModule from '@/store/modules/actionDate'
import Container from '@/components/common/Container'
import { sysMixins } from '@/assets/mixins/sys'

// import left from './components/LeftDate'
import center from './components/Center'

export default {
  mixins: [sysMixins],
  components: {
    Container,
    center,
    leftComponent: () => import('./components/LeftDate'),
    editComponent: () => import('./components/Edit.vue'),
    listComponent: () => import('./components/List'),
    detailComponent: () => import('./components/Detail.vue')
  },
  created () {
    // 注册动态store模块
    if (!this.$store.hasModule('actionDate')) {
      this.$store.registerModule('actionDate', actionDateStoreModule)
    }
  },
  mounted () {
    this.setShowLeftBlock(true)
    this.setShowRightBlock(false)
    this.setLeftComponent('leftComponent')
    this.setBottomComponent('listComponent')
    this.setBottomHeight('300px')
  },
  methods: {
    handleLoadMapData (stations, isEdit, chooseDate) {
      this.$refs['actionDate-center'].LoadMapData(stations, isEdit, chooseDate)
    },
    handleLoadBottomData () {
      this.$refs['actionDate-bottm'].handleSearch()
    }
  },
  destroyed () {
    // 卸载installSetting动态store模块
    this.$store.commit('reset_state')
    this.$store.unregisterModule('actionDate')

    this.setLinkTagArray()
    this.setLinkRouterMaps()
    this.setActivedTagName('')
  }
}
</script>
