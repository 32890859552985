<template>
    <div>
        <SvgStation ref="svgStation" :stationSvgModal.sync="stationSvgModal" :stationId="chooseStationId" :svgShowType="4"
          :searchSchedules="[{startDate: actionDate, endDate: actionDate}]"
          @svg-finished="svgFinished" @on-select="handleSelectSvgResource" @svg-show-alldemand="handleShowAllDemand"
          @handleChange3dMode="handleBeginRevice3d" />

        <ThreeStation ref="threeStation" @three-finished="threeFinished" @handleChangeSvgMode="handleShowStationDemand" />

        <!-- 弹窗显示上刊照片反馈 -->
        <Modal v-model="showDemandImage" width="1000" :footer-hide="true">
            <p slot="header" class="text-center">
                <Icon type="ios-information-circle"></Icon>
                <span>灯箱编号：{{chooseResouceCode}}
                <Icon type="ios-analytics" class="p-l-5" />
                (
                  <span v-if="demandBrand !== ''">发布品牌：<span class="text-orange">{{demandBrand}}</span> </span>
                )
                </span>
            </p>
            <div class="text-center">
              <Row class="p-b-5">
                <i-col span="24">
                    <div @click="handleChangeType(1)" :class="choosePhoneType === 1?'workplatform-radio-button-active':'workplatform-radio-button'" class="p-l-20 p-r-20">任务素材</div>

                    <div v-if="workPhotoList.length>0" @click="handleChangeType(2)" :class="choosePhoneType === 2?'workplatform-radio-button-active':'workplatform-radio-button'" class="p-l-20 p-r-20">反馈照片</div>
                    <span v-else class="remark m-l-5">任务未完成，无反馈照片</span>
                </i-col>
              </Row>
              <img-light-box-component v-show="choosePhoneType === 1" ref="demandImgLightBox" :imgIndex.sync="demandImgIndex"/>
              <img-light-box-component v-show="choosePhoneType === 2" ref="workImgLightBox" :imgIndex.sync="workImgIndex"/>
            </div>
        </Modal>

        <Modal v-model="showAllDemandList" width="1000" :footer-hide="true">
          <Table stripe :data="publishResourceList" :columns="tableColumns" height="700"></Table>
        </Modal>
    </div>
</template>

<script>
import SvgStation from '@/components/svg/SvgStation'
import ThreeStation from '@/components/svg/ThreeStation'
import ImgLightBoxComponent from '@/components/common/ImgLightBox'
import { getPlanDetailPage } from '@/api/msp/plan'
import { formartMspPlanStatus } from '@/utils/tagStatus'

export default {
  props: {
    actionDate: String // 选中的作业令日期
  },
  components: {
    SvgStation, ThreeStation, ImgLightBoxComponent
  },
  data () {
    return {
      mapFinished: false,
      stationSvgModal: false, // 弹窗显示SVG站点地图
      chooseStationId: '',
      publishResourceList: [], // 上刊发布的资源信息

      showDemandImage: false,
      // 上刊反馈参数
      chooseResouceCode: '',
      demandBrand: '',

      choosePhoneType: 1,
      demandPhotoList: [], // 上刊素材照片列表
      demandImgIndex: 0,
      workPhotoList: [], // 工单照片
      workImgIndex: 0,

      showAllDemandList: false,
      tableColumns: [
        {
          title: '资源编号',
          render: (h, data) => {
            return h('span', { }, data.row.resourceCode || '无')
          }
        },
        {
          title: '位置',
          render: (h, data) => {
            return h('span', { }, data.row.positionName || data.row.stationName)
          }
        },
        { title: '任务类型', key: 'typeName' },
        { title: '更新时间', key: 'updateTime' },
        {
          title: '状态',
          width: 100,
          align: 'center',
          render: (h, data) => {
            // return h('tag', { props: { color: formatMspPlanStatusColor(data.row.status) } }, data.row.statusName)
            return formartMspPlanStatus(h, data.row.status, data.row.statusName)
          }
        },
        {
          title: '质检',
          width: 80,
          align: 'center',
          render: (h, data) => {
            if (data.row.qualityTesting) {
              return h('Icon', {
                props: { type: 'md-checkbox-outline', size: 16, color: '#19be6b' }
              })
            } else {
              return h('Icon', {
                props: { type: 'md-close', size: 16, color: '#ed4014' }
              })
            }
          }
        },
        {
          title: '详情',
          width: 80,
          align: 'center',
          render: (h, data) => {
            return h('a', {
              on: {
                click: () => {
                  this.handleSelectSvgResource(data.row.deviceId)
                }
              }
            }, '详情')
          }
        }
      ]
    }
  },
  methods: {
    handleShowStationDemand (stationId) { // 地图点击站点事件
      this.chooseStationId = stationId
      this.stationSvgModal = true
    },
    svgFinished () { // SVG站点地图加载完毕
      const postData = {
        actionDate: this.actionDate,
        domainIds: this.chooseStationId,
        pageSize: 100,
        pageNumber: 1
      }

      getPlanDetailPage(postData).then(res => {
        this.publishResourceList = res.list
        this.$refs.svgStation.tagResourceAndName(res.list.map(x => {
          return {
            deviceId: x.deviceId,
            brandName: '',
            stationId: x.domainId,
            floor: x.floor
          }
        }))

        this.$refs.svgStation.choseSpinShow()
      })
    },
    threeFinished () {

    },
    handleSelectSvgResource (deviceId) {
      // 从上刊资源列表找到该资源
      const demandResourceBean = this.publishResourceList.find(x => x.deviceId === deviceId)
      if (demandResourceBean) {
        this.showDemandImage = true
        this.chooseResouceCode = demandResourceBean.resourceCode
        this.demandBrand = ''

        this.demandImgIndex = 0
        this.workImgIndex = 0
        this.demandPhotoList = demandResourceBean.taskitemFiles
        this.workPhotoList = demandResourceBean.taskitemAttachs

        this.handleChangeType(1)
      }
    },
    handleChangeType (type) {
      this.choosePhoneType = type

      const postData = {
        startIndex: 0,
        imgList: []
      }

      if (type === 1) { // 任务素材
        postData.startIndex = this.demandImgIndex
        postData.imgList = this.demandPhotoList.map(x => x.fileKey)
        this.$refs.demandImgLightBox.init(postData)
      } else { // 反馈照片
        postData.startIndex = this.workImgIndex
        postData.imgList = this.workPhotoList.map(x => x.fileKey)
        this.$refs.workImgLightBox.init(postData)
      }
    },
    handleShowAllDemand () {
      this.showAllDemandList = true
    },
    handleBeginRevice3d (stationId) {
      this.$refs.threeStation.initData(stationId)
    }
  }
}
</script>
